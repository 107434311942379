import React, { Fragment, useState, useEffect } from "react"
import { StaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Header from "./Header"
import Footer from "./Footer"
import "../../stylesheets/main.scss"
import Navigation from "./Navigation"
import NewsletterPopup from "../popup/newsletterPopup"
import Cookies from "js-cookie"

export default (props) => (
  <StaticQuery
    query={graphql`
      query SiteQuery {
        site {
          siteMetadata {
            title
            description
          }
        }
        allPrismicHomepage {
          edges {
            node {
              data {
                seotitle
                seodescription
                body {
                  ... on PrismicHomepageBodyNewsletterSubscription {
                    slice_type
                    slice_label
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => <Layout data={data} {...props} />}
  />
)

const Layout = (props) => {
  // Define the meta title and description
  const { title, description } = props.data.site.siteMetadata

  const homeContent = props.data.allPrismicHomepage.edges[0].node

  const [navigationActive, setNavigatonActive] = useState(false)
  const [newsletterPopupActive, setNewsletterPopupActive] = useState(false)

  const overlayClick = () => {
    if (newsletterPopupActive) {
      Cookies.set("swoonSubscription", true)
    }

    setNavigatonActive(false)
    setNewsletterPopupActive(false)
  }

  const toggleNavigation = () => {
    setNavigatonActive(!navigationActive)
  }

  const closePopup = () => {
    Cookies.set("swoonSubscription", true)
    setNewsletterPopupActive(false)
  }

  useEffect(() => {
    const subscriptionCookie = Cookies.get("swoonSubscription")

    if (!subscriptionCookie) {
      setNewsletterPopupActive(true)
    }
  }, [])

  return (
    <Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{homeContent.seotitle ? homeContent.seotitle : title}</title>
        <meta
          name="description"
          content={
            homeContent.seodescription
              ? homeContent.seodescription
              : description
          }
        />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
      </Helmet>
      <div
        className={`page-wrapper ${
          navigationActive ? `navigation-active` : ``
        }`}
      >
        <Navigation
          toggleNavigation={toggleNavigation}
          navigationActive={navigationActive}
        />
        <div className="content-wrapper">
          <Header
            toggleNavigation={toggleNavigation}
            navigationActive={navigationActive}
          />
          <NewsletterPopup
            active={newsletterPopupActive}
            closePopup={closePopup}
          >
            <main role="main">{props.children}</main>
          </NewsletterPopup>
          <Footer />
        </div>
        {(navigationActive || newsletterPopupActive) && (
          <button
            className={`overlay ${newsletterPopupActive ? "priority" : ""}`}
            onClick={overlayClick}
            aria-label="Close overlay"
          />
        )}
      </div>
    </Fragment>
  )
}
