import React, { Fragment } from "react"
import { Logo } from "./../svg"
import { StaticQuery, Link, graphql } from "gatsby"
import linkResolver from "../../utils/linkResolver"

export default (props) => (
  <StaticQuery
    query={graphql`
      query FooterQuery {
        allPrismicFooter(limit: 1) {
          edges {
            node {
              data {
                footer_links {
                  footer_link_title
                  footer_link {
                    link_type
                    __typename
                    url
                    document {
                      ... on PrismicStandardContentPage {
                        data {
                          title {
                            text
                          }
                        }
                      }
                      ... on PrismicHomepage {
                        data {
                          title {
                            text
                          }
                        }
                      }
                    }
                  }
                }
                footer_text {
                  text
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => <Footer key={data} data={data} {...props} />}
  />
)

const Footer = (props) => {
  const { data } = props.data.allPrismicFooter.edges[0].node
  let footerLinks = data.footer_links.map((link, index) => {
    if (link.footer_link.__typename === "PRISMIC__ExternalLink") {
      return (
        <Fragment key={index}>
          <a
            className="footer-link link"
            href={link.footer_link.url}
            target="_blank"
            rel="nofollow noreferrer"
          >
            <span>{link.footer_link_title}</span>
          </a>
          <span>|</span>
        </Fragment>
      )
    }
    return (
      <Fragment key={index}>
        <Link className="footer-link" to={linkResolver(link)}>
          {link.footer_link_title}
        </Link>
        <span>|</span>
      </Fragment>
    )
  })
  const year = new Date().getUTCFullYear()

  const scrollToTop = (e) => {
    e.preventDefault()
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }

  return (
    <footer className="footer">
      <div className="container">
        <Link className="footer-logo-link" to="/">
          <Logo className="footer-logo" />
        </Link>
        {footerLinks && (
          <div className="footer__links">
            {footerLinks}
            <button className="footer-link link" onClick={scrollToTop}>
              Back to top
            </button>
          </div>
        )}
        <p className="footer__footnote">{data.footer_text.text}</p>
        <p className="footer__copyright">&copy; Copyright {year} Swoonlove</p>
      </div>
    </footer>
  )
}
