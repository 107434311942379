import React from "react"
import { StaticQuery, Link, graphql } from "gatsby"
import linkResolver from "../../utils/linkResolver"
import { Logo, IconHamburger, IconClose } from "./../svg"

export default (props) => (
  <StaticQuery
    query={graphql`
      query HeaderQuery {
        allPrismicNavigation {
          edges {
            node {
              data {
                primary_navigation {
                  align_left_right_in_header_menu
                  show_in_header_menu
                  link {
                    link_type
                    url
                    uid
                    type
                    document {
                      ... on PrismicHomepage {
                        data {
                          title {
                            text
                          }
                        }
                      }
                      ... on PrismicLatestFavsListing {
                        data {
                          title {
                            text
                          }
                        }
                        uid
                        id
                        type
                      }
                      ... on PrismicStandardContentPage {
                        data {
                          title {
                            text
                          }
                        }
                        uid
                        id
                        type
                      }
                      ... on PrismicLatestEditsListing {
                        data {
                          title {
                            text
                          }
                        }
                        uid
                        id
                        type
                      }
                      ... on PrismicShopMyInstagram {
                        data {
                          title {
                            text
                          }
                        }
                      }
                      ... on PrismicEdit {
                        data {
                          title {
                            text
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => <Header data={data} {...props} />}
  />
)

const Header = (props) => {
  const { data } = props.data.allPrismicNavigation.edges[0].node
  const navItems = data.primary_navigation
  const { toggleNavigation, navigationActive } = props

  const leftHandItems = navItems.map((item, index) => {
    if (item.show_in_header_menu && !item.align_left_right_in_header_menu) {
      return (
        <Link
          key={index}
          to={linkResolver(item.link)}
          className="header-nav__link-item link"
        >
          <span>{item.link.document.data.title.text}</span>
        </Link>
      )
    }
    return false
  })

  const rightHandItems = navItems.map((item, index) => {
    if (item.show_in_header_menu && item.align_left_right_in_header_menu) {
      return (
        <Link
          key={index}
          to={linkResolver(item.link)}
          className="header-nav__link-item link"
        >
          <span>{item.link.document.data.title.text}</span>
        </Link>
      )
    }
    return false
  })

  return (
    <header className="header">
      <div className="header-nav">
        <button
          onClick={() => {
            toggleNavigation()
          }}
          className="header-nav__toggle"
        >
          {!navigationActive && <IconHamburger />}
          {navigationActive && <IconClose />}
        </button>
        <div className="header-nav__left-links">{leftHandItems}</div>
        <div className="header-nav__logo">
          <Link to="/">
            <Logo />
          </Link>
        </div>
        <div className="header-nav__right-links">{rightHandItems}</div>
      </div>
    </header>
  )
}
