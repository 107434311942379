import React from "react"
import { StaticQuery, Link, graphql } from "gatsby"
import linkResolver from "../../utils/linkResolver"
import { IconHamburger, IconClose, IconInstagram, Logo } from "./../svg"
import { RichText } from "prismic-reactjs"

export default (props) => (
  <StaticQuery
    query={graphql`
      query NavigationQuery {
        allPrismicNavigation {
          edges {
            node {
              dataRaw
              data {
                primary_navigation {
                  link {
                    link_type
                    url
                    document {
                      ... on PrismicHomepage {
                        data {
                          title {
                            text
                          }
                        }
                        id
                        url
                        type
                      }
                      ... on PrismicStandardContentPage {
                        data {
                          title {
                            text
                          }
                        }
                        id
                        url
                        type
                        uid
                      }
                      ... on PrismicLatestFavsListing {
                        data {
                          title {
                            text
                          }
                        }
                        id
                        url
                        type
                        uid
                      }
                      ... on PrismicLatestEditsListing {
                        data {
                          title {
                            text
                          }
                        }
                        id
                        url
                        type
                        uid
                      }
                      ... on PrismicShopMyInstagram {
                        data {
                          title {
                            text
                          }
                        }
                        id
                        url
                        type
                        uid
                      }
                      ... on PrismicEdit {
                        data {
                          title {
                            text
                          }
                        }
                        id
                        url
                        type
                        uid
                      }
                    }
                  }
                }
                secondary_navigation {
                  link {
                    link_type
                    document {
                      ... on PrismicStandardContentPage {
                        data {
                          title {
                            text
                          }
                        }
                        id
                        url
                        type
                        uid
                      }
                      ... on PrismicLatestFavsListing {
                        data {
                          title {
                            text
                          }
                        }
                        id
                        url
                        type
                        uid
                      }
                      ... on PrismicLatestEditsListing {
                        data {
                          title {
                            text
                          }
                        }
                        id
                        url
                        type
                        uid
                      }
                      ... on PrismicShopMyInstagram {
                        data {
                          title {
                            text
                          }
                        }
                        id
                        url
                        type
                        uid
                      }
                      ... on PrismicEdit {
                        data {
                          title {
                            text
                          }
                        }
                        id
                        url
                        type
                        uid
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => <Navigation data={data} {...props} />}
  />
)

const Navigation = (props) => {
  const { navigationActive, toggleNavigation, data } = props
  const allNavItems = data.allPrismicNavigation.edges[0].node.data
  const primaryNavItems = allNavItems.primary_navigation
  const secondaryNavItems =
    data.allPrismicNavigation.edges[0].node.dataRaw.secondary_navigation

  return (
    <div className={`menu-wrapper`}>
      <nav className="navigation">
        <div className="navigation__inner">
          <Logo className="navigation__logo" />
          {primaryNavItems && (
            <ul className="navigation__secondary-menu">
              {primaryNavItems.map((navItem, index) => {
                const item = navItem.link.document
                return (
                  <li key={index}>
                    <Link
                      to={linkResolver(item)}
                      className="navigation__link link"
                      onClick={() => toggleNavigation()}
                    >
                      <span>{item.data.title.text}</span>
                    </Link>
                  </li>
                )
              })}
            </ul>
          )}
          {secondaryNavItems && (
            <ul className="navigation__secondary-menu">
              {secondaryNavItems.map((navItem, index) => {
                const item = navItem.link
                if (item.__typename === "PRISMIC__ExternalLink") {
                  return (
                    <a
                      key={index}
                      href={item.url}
                      className="navigation__link -secondary link--multiline"
                      target="_blank"
                      rel="nofollow noreferrer"
                    >
                      <span>{navItem.title}</span>
                    </a>
                  )
                } else {
                  return (
                    <li key={index}>
                      <Link
                        to={linkResolver(item)}
                        className="navigation__link -secondary link link"
                      >
                        <span>
                          {navItem.title
                            ? RichText.asText(navItem.title)
                            : RichText.asText(item.title)}
                        </span>
                      </Link>
                    </li>
                  )
                }
              })}
            </ul>
          )}
          <div className="social-links">
            <a
              href="https://www.instagram.com/swoon_love"
              target="_blank"
              rel="noreferrer nofollow"
              title="swoon_love"
            >
              <IconInstagram className="icon icon--instagram" />
            </a>
          </div>
        </div>
      </nav>
      <button className="menu-bar" onClick={() => toggleNavigation()}>
        <div className="menu-bar__inner">
          <div className="menu-bar__background" />
          <div className="menu-bar__icon">
            {!navigationActive && <IconHamburger />}
            {navigationActive && (
              <IconClose className="icon icon--navigation-close" />
            )}
          </div>
          <div className="menu-bar__text">
            <span>Menu</span>
          </div>
          <div className="menu-bar__contact">
            <a
              href="mailto:hello@swoon.love"
              target="_blank"
              rel="noreferrer nofollow"
            >
              <span></span>hello@swoon.love
            </a>
          </div>
        </div>
      </button>
    </div>
  )
}
