import React, { useState } from "react"
import addToMailchimp from "gatsby-plugin-mailchimp"
import { useStaticQuery, graphql } from "gatsby"
// import Img from "gatsby-image"

export default ({
  showImage = false,
  closePopup = false,
  color,
  className,
}) => {
  const data = useStaticQuery(graphql`
    query {
      allPrismicHomepageBodyNewsletterSubscription {
        nodes {
          primary {
            newsletter_subscription_content {
              text
            }
            newsletter_subscription_title {
              text
            }
            image {
              url
            }
          }
        }
      }
    }
  `)

  const {
    newsletter_subscription_title,
    newsletter_subscription_content,
    image,
  } = data.allPrismicHomepageBodyNewsletterSubscription.nodes[0].primary

  let [firstname, setfirstname] = useState("")
  let [email, setemail] = useState("")
  const [emailInvalid, setEmailInvalid] = useState(false)
  const [nameInvalid, setNameInvalid] = useState(false)
  let [subscriptionSuccess, setSubscriptionSuccess] = useState(false)
  let [subscriptionError, setSubscriptionError] = useState(false)
  let [subscriptionPending, setSubscriptionPending] = useState(false)

  if (!className || className === undefined) {
    className = ""
  }

  const handleSubmit = async (e, email, fields) => {
    e.preventDefault()

    setSubscriptionPending(true)

    await addToMailchimp(email, fields).then((response) => {
      if (response && response.result === "success") {
        setSubscriptionPending(false)
        setSubscriptionSuccess(true)
        setSubscriptionError(false)
        return
      }

      if (response && response.result === "error") {
        setSubscriptionPending(false)
        setSubscriptionSuccess(false)
        setSubscriptionError(response.msg)
        return
      }
    })
  }

  const handleFirstNameChange = (e) => {
    setfirstname(e.target.value)
  }

  const handleEmailChange = (e) => {
    setemail(e.target.value)
  }

  const subscribeAnotherEmail = (e) => {
    e.preventDefault()
    setfirstname("")
    setemail("")
    setSubscriptionSuccess(false)
    setSubscriptionError(false)
    setSubscriptionPending(false)
    setEmailInvalid(false)
    setNameInvalid(false)
  }

  return (
    <div className={`newsletter-subscription ${className}`}>
      <div className="newsletter-subscription__inner">
        <div
          className={`newsletter-subscription--active ${
            subscriptionSuccess || subscriptionError || subscriptionPending
              ? "inactive"
              : ""
          }`}
        >
          {newsletter_subscription_title && (
            <h3
              className={`newsletter-subscription__title [ title -${
                color ? color : "white"
              } ]`}
            >
              {newsletter_subscription_title.text}
            </h3>
          )}
          <p>{newsletter_subscription_content.text}</p>
          <form
            className="newsletter-subscription__form"
            onSubmit={(e) => handleSubmit(e, email, { FNAME: firstname })}
          >
            <input
              name="FNAME"
              id="mce-FNAME"
              className={`[ input ${nameInvalid ? "-invalid" : ""} ]`}
              type="text"
              placeholder="Enter your first name"
              value={firstname}
              onChange={(val) => handleFirstNameChange(val)}
              onInvalid={() => setNameInvalid(true)}
              required
            />
            <input
              name="EMAIL"
              className={`[ input ${emailInvalid ? "-invalid" : ""} ]`}
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(val) => handleEmailChange(val)}
              onInvalid={() => setEmailInvalid(true)}
              required
            />
            <button
              type="submit"
              className={`button button--transparent button--${
                color ? color : "white"
              }`}
            >
              Subscribe
            </button>
          </form>
          <p className="newsletter__privacy">Swoonlove respects your privacy</p>
        </div>
        {subscriptionPending && (
          <div className="newsletter-subscription--success">
            <p className={`title -${color ? color : "white"} h3`}>Loading</p>
          </div>
        )}

        {subscriptionSuccess && (
          <div className="newsletter-subscription--success">
            <p className={`title h3 -${color ? color : "white"}`}>
              Thank you for subscribing!
            </p>
            <div className="button-holder">
              {!closePopup ? (
                <button
                  className="button -white"
                  onClick={subscribeAnotherEmail}
                >
                  Add another email?
                </button>
              ) : (
                <button className="button" onClick={closePopup}>
                  Come on in
                </button>
              )}
            </div>
          </div>
        )}

        {subscriptionError && (
          <div className="newsletter-subscription--success">
            <p className={`[ title h3 -${color ? color : "white"} ]`}>
              Oops, something went wrong...
            </p>
            <p className={`[ title -${color ? color : "white"} lowercase ]`}>
              We weren't able to add you to our subscription list at this time.
              Please try again later.
            </p>
          </div>
        )}
      </div>
      {showImage && (
        <div className="newsletter-subscription__image">
          <img
            src={image.url}
            alt={image.alt}
            loading="lazy"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </div>
      )}
    </div>
  )
}
