import React from "react"
import NewsletterSubscription from "../slices/NewsletterSubscription"
import { IconClose } from "./../svg"

export default ({ children, active, slice, closePopup }) => {
  return (
    <>
      {active && (
        <div className="popup">
          <div className="container">
            <div className="popup__inner">
              <NewsletterSubscription
                className="newsletter-subscription__popup"
                slice={slice}
                color="black"
                showImage={false}
                closePopup={closePopup}
              />
              <button className="popup__close" onClick={closePopup}>
                <IconClose className="icon icon--close" />
              </button>
            </div>
          </div>
        </div>
      )}
      {children}
    </>
  )
}
